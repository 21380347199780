@media only screen and (max-width:600px) {

    .kk-header-home .main-logo,
    .kk-header-alt .main-logo {
        max-height: 35px;
    }

    .kk-footer .kk-tour-logo {
        height: 60px;
    }

    .kk-page-home .kk-hero::after {
        bottom: -20%;
        height: 35vh;
    }

    .full-vh {
        margin-bottom: 160px !important;
    }

    .react-3d-carousel .slider-container .slider-content .slider-single.proactive .slider-single-content {
        left: 15%;
    }

    .react-3d-carousel .slider-container .slider-content .slider-single.preactive .slider-single-content {
        left: -15%;
    }

    .homebanner-head {
        font-size: 36px !important;
    }
}

@media only screen and (min-width:430px) and (max-width:767px) {
    .bg-grey {
        padding-bottom: 25%;
    }

    .kk-header-home .main-logo,
    .kk-header-alt .main-logo {
        max-height: 50px;
    }

    .kk-footer .kk-tour-logo {
        height: 50px;
    }
}

@media only screen and (min-width:1024px) and (max-width:1250px) {
    .kk-page-home .kk-hero::after {
        position: absolute;
        bottom: -20%;
        height: 45vh;
    }

    .full-vh {
        margin-bottom: 320px !important;
    }
}

@media only screen and (max-width:1024px) {
    .full-vh {
        margin-bottom: 300px;
    }

    .kk-page-home .kk-slider h4 {
        font-size: 30px;
    }

    .kk-page-home .kk-slider h5 {
        font-size: 26px;
    }
}

@media(width < 768px) {
    .kk-footer-align {
        text-align: center;
    }

    .privatectr {
        position: relative;
        top: 0px;
    }

    .kk-page-home .kk-slider p {
        width: 100%;
    }

    .kk-footer .kk-govt-logo {
        margin-top: 0px;
        margin-bottom: 18px;
    }

    .kk-footer .kk-namma-kumari {
        margin-top: 0px;
        margin-bottom: 18px;
    }

    .kk-widget-header h4 {
        font-size: 23px;
        /* word-break: break-all; */
    }

    .kk-header-home .navbar-nav .dropdown-menu .dropdown-item,
    .kk-header-alt .navbar-nav .dropdown-menu .dropdown-item {
        font-size: 12px;
    }
}

@media(width <=991px) {
    .switch-wrapper {
        display: none;
    }

    .navbar-toggler {
        text-align: left;
    }

    .navbar-collapse {
        text-align: left;
        height: unset;
    }

    .kk-header-home .navbar-collapse {
        text-align: left;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        border-radius: 10px;
        background: linear-gradient(177deg, rgb(198 77 82) 60%, rgb(90 31 52) 100%);
        /* box-shadow: 1px 0px 0px 1px #4b4d4fde; */
        width: fit-content;
    }

    .kk-header-home .navbar-nav .dropdown-menu {
        background-color: rgba(0, 0, 0, 0.476);
        border: none;
    }

    .kk-header-alt .navbar-nav .dropdown-menu {
        background-color: rgba(0, 0, 0, 0.476);
        text-align: center;
        width: -moz-fit-content;
        width: fit-content;
    }

    .switch-wrapper-align {
        margin-top: 10px;
    }

    .kk-page-about .kk-section {
        display: block;
        padding: 0px 0px;
    }

    .kk-header-home .navbar-nav a,
    .kk-header-alt .navbar-nav a {
        font-size: 14px;
        padding-left: 20px !important;
        font-weight: 600;
        font-family: 'proximanova-regular-webfont';
        padding-right: 20px;
    }

    /* #responsive-navbar-nav .nav-item:after {
        top: 7px;
        font-size: 14px;
        left: 75px;
    }

    #responsive-navbar-nav .nav-item.show:after {
        top: 7px;
        font-size: 14px;
        left: 75px;
    } */

    .kk-header-home .navbar-nav .dropdown-menu,
    .kk-header-alt .navbar-nav .dropdown-menu {
        padding: 0px 0px;
    }

    .continue-button {
        margin-bottom: 15px;
    }

    .kk-page-exploreinfo .kk-section h1 {
        font-size: 23px;
    }

    .kk-page-exploreinfo .kk-section h4 {
        font-size: 23px;
    }
}

@media(width > 991px) {
    .switch-wrapper-align {
        display: none;
        margin-top: 10px;
    }

    .kk-header-home,
    .kk-header-alt .navbar {
        padding-left: 60px;
        padding-right: 60px;
    }

    .kk-footer {
        padding-left: 70px;
        padding-right: 70px;
    }
}

@media(width <=767px) {
    .kk-page-home .kk-hero h1 {
        font-size: 30px;
    }

    .sliderContentCtr {
        margin-top: 0%;
    }

    .kk-page-home .kk-hero h5 {
        font-size: 28px;
    }
}

@media(600px < width < 990px) {
    .kk-page-home .kk-hero::after {
        bottom: -30%;
        height: 45vh;
    }
}

@media(width >=600px) {
    .kk-widget-header {
        background-size: 100% 100% !important;
    }
}

@media(width <=599px) {
    .kk-widget-header {
        height: 120px;
    }

    .kk-widget-header.temples,
    .kk-widget-header.churches,
    .kk-widget-header.beaches,
    .kk-widget-header.mosques,
    .kk-widget-header.eco-tourisms,
    .kk-widget-header.harbours,
    .kk-widget-header.parks-and-forts {
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}

@media only screen and (max-width:575px) {
    .navbar-collapse {
        text-align: left;
        height: unset;
        transform: translate(-30%);
    }

    .guideline-heading {
        font-size: 18px !important;
    }

    .guideline-heading span {
        font-size: 18px !important;
    }
}