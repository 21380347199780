@font-face {
    font-family: 'prequel_demoregular';
    src: url('./assets/fonts/prequeldemo-regular-webfont.woff2') format('woff2'),
        url('./assets/fonts/prequeldemo-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_altbold';
    src: url('./assets/fonts/proximanova-bold-webfont.woff2') format('woff2'),
        url('./assets/fonts/proximanova-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proximanova-regular-webfont';
    src: url('./assets/fonts/proximanova-regular-webfont.woff2') format('woff2'),
        url('./assets/fonts/proximanova-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html,
body {
    height: 100%;
    min-height: 100%;
    margin: 0;
    margin: 0;
}

html {
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: #888;
    width: 100%;
    min-height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'prequel_demoregular', 'proxima_nova_altbold',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: #888;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.full-vh {
    height: 100vh;
    margin-bottom: 26%;
}

.full-h {
    height: 100%;
}

.headfull-w {
    width: 100%;
}

.full-w {
    width: 100%;
}

#root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    position: relative;
}

#root>.suspense-loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: #fff;
}


.bg-white {
    background-color: #FFFFFF;
}

.bg-grey {
    background-color: #F5F5F5;
}

.web-layout {
    position: relative;
}

.web-layout .page-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0;
    background-color: #0000002e;
    z-index: 999;
}

.web-layout .content-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    background-color: #0000002e;
    z-index: 999;
}

.web-layout .page-loader>.loader {
    display: flex;
    margin: 0 auto;
}

.btn-block {
    width: 100%;
    height: 100%;
}

.kk-section {
    display: block;
    padding: 40px 0px;
}

.kk-section h4 {
    font-size: 30px;
    color: #000;
    font-family: 'proxima_nova_altbold';
}

.kk-section h1 {
    font-size: 30px;
    color: #000;
    font-family: 'proxima_nova_altbold';
}

.kk-section p {
    font-size: 18px;
    color: #212529;
    font-family: 'proximanova-regular-webfont'
}

.navbar-collapse {
    height: 100%;
}

.kk-header-home {
    position: absolute;
    width: 100%;
    top: 20px;
    z-index: 9;
    margin-bottom: 400px;
}

/* .kk-header-home,
.kk-header-alt {
    height: 90px;
} */

.kk-header-alt {
    margin-top: 20px;
    margin-bottom: 20px;
}

.kk-header-home .navbar,
.kk-header-alt .navbar {
    /* height: 100%; */
    padding: 0;
}

.kk-header-home .navbar-light {
    background-color: transparent !important;
}

.kk-header-home .main-logo,
.kk-header-alt .main-logo {
    max-height: 72px;
}

.kk-header-home .main-logo.separtor,
.kk-header-alt .main-logo.separtor {
    position: relative;
    margin-right: 20px;
    padding-right: 20px;
}

.kk-header-home .main-logo.separtor {
    border-right: 1px solid #fff;
}

.kk-header-alt .main-logo.separtor {
    border-right: 1px solid #000;
}

.kk-header-home .navbar-nav a,
.kk-header-alt .navbar-nav a {
    font-size: 18px;
    padding-left: 20px !important;
    font-weight: 600;
}

.kk-header-home .navbar-nav a {
    color: #FFFFFF;
}

.kk-header-alt .navbar-nav a {
    color: #000000;
}

.kk-header-home .navbar-nav a.active {
    color: #F3B918;
}

.kk-header-alt .navbar-nav a.active {
    color: #F3B918;
}

.kk-header-home .navbar-nav .dropdown-menu,
.kk-header-alt .navbar-nav .dropdown-menu {
    background-color: #00000091;
    padding: 20px 0px;
    text-align: center;
    width: fit-content;
}

.kk-header-home .navbar-nav .dropdown-menu .dropdown-item,
.kk-header-alt .navbar-nav .dropdown-menu .dropdown-item {
    margin: 0 !important;
    padding: 8px 4px !important;
    color: #ffff;
    font-size: 14px;
    font-weight: 700;
}

.kk-header-home .navbar-nav .nav-item.show>a,
.kk-header-alt .navbar-nav .nav-item.show>a {
    color: #F3B918;
}

.kk-header-home .navbar-nav .dropdown-menu .dropdown-item.active,
.kk-header-alt .navbar-nav .dropdown-menu .dropdown-item:focus,
.kk-header-alt .navbar-nav .dropdown-menu .dropdown-item.active {
    background-color: transparent;
    color: #F3B918;
}

.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item:active {
    background-image: linear-gradient(to left top, #781F32, #DD594C);
    color: #000 !important;
}

.dropdown-toggle.nav-link {
    padding-left: 20px;
    padding-right: 20px !important;
}

.dropdown-toggle::after {
    display: none;
}

.navbar-collapse .nav-item {
    position: relative;
}

.navbar-collapse .nav-item:after {
    color: #000;
}

.navbar-collapse .nav-item.show:after {
    color: #F3B918;
}

.kk-widget-header {
    height: 340px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.kk-widget-header h4 {
    font-size: 36px;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-family: 'prequel_demoregular';
}

.kk-widget-header.temples {
    background: url(./assets/images/banners/banner_temples.png) no-repeat center center;
}

.kk-widget-header.churches {
    background: url(./assets/images/banners/banner_church.png) no-repeat center center;
}

.kk-widget-header.beaches {
    background: url(./assets/images/banners/banner_falls.png) no-repeat center center;
}

.kk-widget-header.mosques {
    background: url(./assets/images/banners/banner_mosque.png) no-repeat center center;
}

.kk-widget-header.eco-tourisms {
    background: url(./assets/images/banners/eco_tourism.png) no-repeat center center;
}

.kk-widget-header.harbours {
    background: url(./assets/images/banners/banner_harbour.png) no-repeat center center;
}

.kk-widget-header.parks-and-forts {
    background: url(./assets/images/banners/banner_parks.png) no-repeat center center;
}


.kk-page {
    width: 100%;
    min-height: 100%;
    height: auto;
}

.kk-page-home .kk-hero {
    position: relative;
    height: 600px;
    min-height: 100%;
    background: url(./assets/images/bg/hero_bg.png) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.kk-page-home .kk-hero::after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    right: 0;
    height: 70vh;
    width: 100%;
    background: url(./assets/images/bg/hero_bg_nxt.png);
    background-size: 103% 100%;
}

.kk-page-home .kk-hero h1 {
    font-size: 56px;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-family: 'prequel_demoregular';
}

.kk-page-home .kk-hero h5 {
    font-size: 36px;
    color: #F3B918;
    font-family: 'prequel_demoregular';
}

.kk-page-home .kk-slider h4 {
    font-family: 'prequel_demoregular';
    color: #080707;
    font-size: 36px;
}

.kk-page-home .kk-slider h5 {
    font-family: 'prequel_demoregular';
    color: #F3B918;
    font-size: 30px;
}

.kk-page-home .kk-slider p {
    font-size: 16px;
    color: #000;
    width: 90%;
}

.kk-page-home .kk-slider .slider-wrapper {
    height: 300px;
    display: block;
}

.kk-page-home .kk-info {
    background-image: url("./assets/images/bg/category_bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.kk-page-home .kk-info h4 {
    font-family: 'proxima_nova_altbold';
    color: #FFFFFF;
    font-size: 23px;
    margin-bottom: 20px;
}

.kk-page-home .kk-info p {
    color: #b7b7b7;
    font-size: 16px;
    font-family: 'proxima_nova_altbold';
}

.kk-page-home .kk-info ul.ul-category {
    text-align: center;
}

.kk-page-home .kk-info ul.ul-category .icon>img {
    width: 40px;
    height: 40px;
}

.kk-page-home .kk-info ul.ul-category .numbers {
    font-family: 'proxima_nova_altbold';
    color: #F3B918;
    font-size: 40px;
}

.kk-page-home .kk-info ul.ul-category .title {
    font-family: 'proxima_nova_altbold';
    color: #FFFFFF;
    font-size: 24px;
}

.kk-page-home .kk-map .map-wrapper {
    height: 520px;
    border-radius: 4px;
    position: relative;
}

.kk-page-home .kk-map .btn-block.active {
    background-color: #F9E9BE;
}

.kk-page-home .kk-map .btn-block>span {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
}

.kk-page-home .kk-map .btn-block>span>img {
    height: 26px;
    width: 26px;
}


.kk-page-explore .kk-page-info {
    height: 440px;
}

.kk-page-explore .kk-page-info .map-wrapper {
    height: 100%;
}

.kk-page-explore .img-box {
    border-radius: 14px;
    height: 244px;
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 80px;
}

.kk-page-explore .img-box>img {
    width: 100%;
    height: 100%;
}

.kk-page-explore .img-box .caption {
    position: absolute;
    bottom: -40px;
    left: 24px;
    right: 24px;
    height: 80px;
    background-color: #FFFFFF;
    text-align: center;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.kk-page-exploreinfo .kk-map .map-wrapper {
    height: 480px;
    border-radius: 4px;
    position: relative;
}


.kk-reach {
    box-sizing: border-box;
    border: 1px solid #F3B918;
    padding: 20px;
    background-color: #d3d3d357;
    border-radius: 12px
}


.kk-events-fonts h4,
.kk-events-fonts h5,
.kk-events-fonts h1 {
    font-family: 'prequel_demoregular';
}

.kk-events-fonts p,
.kk-events-fonts li {
    font-size: 16px;
}

.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: 'Arvo', serif;
}

.page_404 img {
    width: 50%;
}

.four_zero_four_bg {
    background-image: url(https://media.istockphoto.com/id/651083818/vector/error-404-page-with-a-painter-vector-illustration.jpg?s=612x612&w=0&k=20&c=u6sfxKa8hZ_Ew95WIrZKkJSFXnYiOkW4paMqsqbDeuQ=);
    height: 50vh;
    background-position: center;
    background-repeat: no-repeat;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: black !important;
    padding: 10px 20px;
    background: #F3B918;
    border-radius: 20px;
    margin: 20px 0;
    display: inline-block;
}

.contant_box_404 {
    margin-top: 50px;
}


.prequel_demoregular {
    font-family: 'prequel_demoregular';
}

.proximanova-regular-webfont {
    font-family: 'proximanova-regular-webfont';
}

.heading-fs {
    font-size: 40px;
}

.warn_color {
    color: #F3B918;
}

.form-control {
    font-size: 14px;
    line-height: 2.5;
    font-family: 'proximanova-regular-webfont';
}


.kk-footer {
    padding: 40px 0px;
    background-color: #000;
}

.kk-footer .kk-govt-logo {
    height: 46px;
    margin-top: 77px;
}

.kk-footer .kk-namma-kumari {
    height: 95px;
    border-radius: 50%;
    margin-top: 56px
}

.kk-footer .kk-tour-logo {
    height: 75px;
}

.kk-footer .kk-quick-links {
    margin: 0;
    padding: 20px 0px;
    display: block;
    text-align: center;
}

.kk-footer .kk-quick-links>li {
    display: inline-block;
    padding: 0px 5px;
}

.kk-footer .kk-quick-links>li>a,
.kk-footer p>a {
    color: #F3B918;
    font-size: 16px;
}

.kk-footer p {
    color: #FFFFFF;
    font-size: 14px;
}

.leaflet-popup-content-wrapper {
    border-radius: 4px !important;
    transition: all 0.3s ease-in-out;
    text-align: right;
}

.leaflet-popup-content-wrapper h4 {
    font-size: 14px;
}

.leaflet-popup-content-wrapper p {
    font-size: 12px;
    color: #888;
    padding: 0 !important;
    margin: 0 !important;
}

.leaflet-popup-content-wrapper a {
    margin-top: 10px;
    display: inline-block;
    text-align: right;
    padding: 4px 12px;
    border: 1px solid #fff;
    border-radius: 1px;
    color: #000 !important;
}

.leaflet-popup-content-wrapper:hover a {
    background-color: #ffefc1;
    border: 1px solid #ffefc1;
}

.react-photo-gallery--gallery img {
    border-radius: 10px !important;
}

.kk-info-padding {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    font-family: 'proximanova-regular-webfont';
    font-weight: 700;
    font-size: 16px;
    color: rgb(23, 22, 22);
}

.kk-info-tamil-padding {
    font-size: 12px;
}


.kk-places h4 {
    font-family: 'prequel_demoregular';
}


#react-select-2-listbox {
    z-index: 999;
}


.kk-gallery-temple {
    width: 100%;
    height: 300px;
    filter: contrast(87%) brightness(87%);
}

.kk-gallery-park {
    width: 100%;
    height: 300px;
    padding-top: 0px;
    margin-top: 10px;
    filter: contrast(87%) brightness(87%);
}

.kk-gallery-church {
    width: 100%;
    height: 300px;
    padding-left: 0px;
    padding-top: 0px;
    margin-top: 10px;
    filter: contrast(87%) brightness(87%);
}

.kk-gallery-tourist {
    width: 100%;
    height: 625px;
    filter: contrast(87%) brightness(87%);
}

.kk-gallery-eco {
    width: 100%;
    height: 300px;
    filter: contrast(87%) brightness(87%);
}

.kk-gallery-sea {
    width: 100%;
    height: 300px;
    margin-top: 10px;
    padding-top: 0px;
    filter: contrast(87%) brightness(87%);
}

.kk-temple-text-position {
    position: relative;
}

.kk-temple-text {
    position: absolute;
    bottom: 6px;
}

.kk-park-text-position {
    position: relative;
}

.kk-park-text {
    position: absolute;
    bottom: 6px;
}

.kk-church-text-position {
    position: relative;
}

.kk-church-text {
    position: absolute;
    bottom: 6px;
}

.kk-tourist-text-position {
    position: relative;
}

.kk-tourist-text {
    position: absolute;
    bottom: 26px;
}

.kk-eco-text-position {
    position: relative;
}

.kk-eco-text {
    position: absolute;
    bottom: 6px;
}

.kk-sea-text-position {
    position: relative;
}

.kk-sea-text {
    position: absolute;
    bottom: 10px;
}

.kk-gallery-images img {
    border-radius: 20px;
}

.kk-gallery-images h3 {
    font-family: 'proxima_nova_altbold';
    color: #FFFFFF;
    font-size: 13px;
    text-shadow: 4px 4px 4px black;
}

.switch-wrapper {
    display: block;
    margin-top: 6px;
    margin-left: 15px;
}

.bg-grey {
    width: 100%;
    display: flex;
    background: #F5F5F5;
    padding-bottom: 10%;
}

.kk-page-explore .bg-grey {
    padding-bottom: 2%;
}

.react-3d-carousel .slider-container .slider-content .slider-single.proactive .slider-single-content {
    left: 25%;
}

.react-3d-carousel .slider-container .slider-content .slider-single.preactive .slider-single-content {
    left: -25%;
}

.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
    box-shadow: none !important;
}

.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content img {
    border-radius: 20px;
}

.react-3d-carousel .slider-container .slider-left div {
    margin-top: 0px !important;
    padding: 10px 15px !important;
    border-radius: 50%;
    border: 1px solid black !important;
}

.react-3d-carousel .slider-container .slider-right div {
    margin-top: 0px !important;
    padding: 10px 15px !important;
    border-radius: 50%;
    border: 1px solid black !important;
}

.slider-left div i {
    color: black;
}

.slider-right div i {
    color: black;
}

.caption-head {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
}

.explore-map {
    height: 350px !important;
}

.stepperform-Ctr label {
    color: black;
    line-height: 1;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
    float: left;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #dc3545 !important;
    border-width: 1.5px !important;
}

.danger {
    color: #dc3545;
    float: left;
}

.location-href a:hover {
    color: #F3B918;
}

a {
    color: black;
}

a:hover {
    color: #F3B918;
}

.gallery-img img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    cursor: pointer;
}

.kk-amountsec-innerCtr {
    background: white;
    box-shadow: 0px 3px 15px #0000001a;
    border-radius: 5px;
    padding: 5px 20px 20px 20px;
    font-size: 16px;
}

.kk-user-icon-position {
    position: relative;
}

.kk-user-icon {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}

.kk-user-img {
    width: 40px;
    border: 1px solid #707070;
    border-radius: 50px;
    padding: 10px;
    background: white;
}

.kk-page-exploreinfo .kk-address {
    background: #F3F5F6;
    border-radius: 10px;
}

.detail-addressctr img {
    width: 20px;
    margin-right: 10px;
}

.detail-addressctr span {
    font-weight: bold;
    font-size: 18px;
    position: relative;
    top: 2px;
}

.table>:not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: white !important;
    font-size: 16px;
}

.kk-loadmore-button {
    width: 200px;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    background-color: #212529;
    font-size: 16px;
}

.kk-loadmore-button:hover {
    color: #f3bc25 !important;
    background-color: #212529;
    border: 1px solid white;
}

.kk-page-explore .d-title {
    font-size: 30px !important;
    font-family: 'prequel_demoregular';
    line-height: 44px;
}

.kk-page-about .kk-section h1 {
    font-family: 'prequel_demoregular';
    color: #080707;
    font-size: 35px;
    text-decoration: none;
}

.kk-page-about .kk-section .warn_color {
    font-family: 'prequel_demoregular';
    color: #F3B918;
    font-size: 31px;
}

.kk-page-about {
    min-height: calc(100vh - 344px);
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* input[type=number] {
    -moz-appearance: textfield;
} */

.css-b62m3t-container {
    z-index: 9999999999;
}

.kk-header-home .navbar-toggler-icon {
    background-image: url("./assets/images/icons/hamburg.png") !important;
}

.kk-header-home .navbar-toggler {
    border: 1px solid white;
}

.kk-header-home .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none !important;
}

.kk-info-dynamic-content p span {
    font-family: 'proximanova-regular-webfont' !important;
}

.sliderContentCtr {
    margin-top: 10%;
}

.toast-container {
    position: relative !important;
}

.react-images__view img {
    border-radius: 20px;
}

.dropdown-toggle:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.kk-content-height,
.kk-page-explore,
.kk-page-exploreinfo {
    min-height: calc(100vh - 346px);
}

.kk-header-home .navbar-nav .nav-item.show>a {
    color: #FFFFFF;
}

.kk-header-alt .navbar-nav .nav-item.show>a {
    color: #000000
}

.kk-info-tamil-padding {
    font-size: 13px;
}

.slider-left {
    right: 103% !important;
}

.pdfviewCtr h3 {
    font-weight: bold;
    margin-bottom: 15px;
}

.pdfviewCtr {
    box-shadow: 0 0 38px 0 rgb(0 0 0 / 8%);
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    font-family: 'proximanova-regular-webfont';
    height: 100%;
}

.pdfviewCtr button:hover {
    background: #f3b91873;
    border-color: #f3b918;
    font-size: 16px;
    letter-spacing: 0.5px;
    padding: 10px 38px;
    width: 100%;
    color: rgb(0 0 0);
}

.pdfviewCtr .btn:active {
    background: #f3b91873;
    border-color: #f3b918;
    font-size: 16px;
    letter-spacing: 0.5px;
    padding: 10px 38px;
    width: 100%;
    color: rgb(0 0 0);
}

.pdfviewCtr button {
    background: #f3b91873;
    border-color: #f3b918;
    font-size: 16px;
    letter-spacing: 0.5px;
    padding: 10px 38px;
    width: 100%;
    color: rgb(0 0 0);
}

.pdfviewCtr button svg {
    font-size: 16px;
    margin-left: 5px;
}

.privatectr {
    font-size: 22px;
    position: relative;
    top: -3px;
}